import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import tick from "../../images/spellcheck.svg";
import price from "../../images/shopping_cart.svg";
import location from "../../images/place.svg";
import truck from "../../images/alarm.svg";
import colors from "../../images/pallette.svg";
import "../css/slider.css";

const Grid = () => {
  return (
    <div tw="relative mx-auto py-4">
      <div tw="text-center tracking-wide text-5xl text-blue-900 font-semibold px-8 py-8">
        OSCAR - YOUR STEEL CONTAINER SUPPLIER
      </div>
      <div tw="font-normal tracking-wide mb-6 mx-6 sm:mx-20 md:mx-40 lgxl:mx-40 px-8">
        Oscar Products has been serving business' throughout the UK for over 20
        years. Our focus has always been to be a reliable business partner that
        offers intelligent packaging solutions.
        <br />
        <div tw="mt-4">
          Oscar’s concept of service is based on consulting clients in all
          aspects,during and after implementation of a project. Our project
          managers ensure professional work, in order to ensure an optimized
          packaging solution for your industry.
        </div>
        <div tw="mt-4">
          We strongly believe that by combining growth with
          environmental-protection and social-responsibility, we shall achieve a
          real sustainable way of doing business.
        </div>
      </div>
      <div tw="flex items-center flex-row flex-wrap justify-center px-24">
        <figure tw="mt-4 w-full sm:w-1/2 lgxl:w-1/5 justify-center mx-auto text-center">
          <img src={tick} alt="Checkmark" tw="h-28 mx-auto" />
          <br />
          <span tw="mx-auto text-center mt-12 text-gray-600 font-semibold">
            CERTIFIED PRODUCTS
          </span>
        </figure>
        <figure tw="mt-4 w-full sm:w-1/2 lgxl:w-1/5 justify-center mx-auto text-center">
          <img src={colors} alt="Checkmark" tw="h-28 mx-auto" />
          <br />
          <span tw="mx-auto text-center mt-12 text-gray-600 font-semibold">
            SELECT YOUR COLOR
          </span>
        </figure>
        <figure tw="mt-4 w-full sm:w-1/2 lgxl:w-1/5 justify-center mx-auto text-center">
          <img src={price} alt="Checkmark" tw="h-28 mx-auto" />
          <br />
          <span tw="mx-auto text-center mt-12 text-gray-600 font-semibold">
            COMPETITIVE PRICING
          </span>
        </figure>
        <figure tw="mt-4 w-full sm:w-1/2 lgxl:w-1/5 justify-center mx-auto text-center">
          <img src={truck} alt="Checkmark" tw="h-28 mx-auto" />
          <br />
          <span tw="mx-auto text-center mt-12 text-gray-600 font-semibold">
            ON-TIME DELIVERIES
          </span>
        </figure>
        <figure tw="mt-4 w-full sm:w-1/2 lgxl:w-1/5 justify-center mx-auto text-center">
          <img src={location} alt="Checkmark" tw="h-28 mx-auto" />
          <br />
          <span tw="mx-auto text-center mt-12 text-gray-600 font-semibold">
            UK WIDE COVERAGE
          </span>
        </figure>
      </div>
      <div tw="py-4 bg-blue-800 w-full mx-0 text-center">
        <div tw="mx-auto text-white font-bold text-3xl">
          Contact Our Team for a Quote
        </div>
        <br />
        <br />
        <div tw="mx-auto text-white font-medium text-lg">
          We can offer customized steel bulk containers as per your requirements
          and shall send you our supply-proposal.
          <br />
          Our team can also help you design a container and required
          lifting-yoke accessories.
        </div>
        <br />
        <br />
        <div tw="mx-auto text-white font-bold text-xl">
          Alternatively you may also contact us on
          <br />
          <br />
          <a
            href="tel:01787 313840"
            tw="no-underline hover:underline text-white"
          >
            01787 313840
          </a>{" "}
          or
          <br />
          <a
            href="mailto:ashish@oscarproducts.com"
            tw="no-underline hover:underline text-white"
          >
            ashish@oscarproducts.com
          </a>
          <br />
          <a
            href="mailto:jackie@oscarproducts.com"
            tw="no-underline hover:underline text-white"
          >
            jackie@oscarproducts.com
          </a>
          <br />
          <a
            href="mailto:fraser@oscarproducts.com"
            tw="no-underline hover:underline text-white"
          >
            fraser@oscarproducts.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default Grid;
