import React from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Image0 from "../../images/new0.jpg";
import Image1 from "../../images/new1.jpg";
import Image2 from "../../images/new2.jpg";
// import Image3 from "../../images/new3.jpg";
import Image4 from "../../images/new4.jpg";
import Image5 from "../../images/new5.jpg";
import Image6 from "../../images/new6.jpg";
import Image7 from "../../images/new7.jpg";
import "../css/slider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Container = tw.div`relative overflow-hidden -ml-2`;
const Content = tw.div`max-w-full`;

const CardSlider = styled(Slider)`
  ${tw`overflow-hidden`}
  .slick-track {
    height: 650px;
  }
  .slick-list {
    ${tw`overflow-hidden`}
  }
  .slick-slide {
    ${tw`h-25width flex justify-center mb-1`}
  }
  .slick-active {
    ${tw`-ml-1`}
  }
  .slick-dots {
    height: 100px;
    z-index: 2;
    margin-bottom: 10px;
  }
  .slick-dots li button:before {
    color: black;
  }
  .slick-dots li.slick-active button:before {
    color: black !important;
  }
  .slick-prev {
    left: 3% !important;
    z-index: 1;
  }
  .slick-prev:before,
  .slick-next:before {
    color: black !important;
  }
  .slick-next {
    right: 3% !important;
    z-index: 1;
  }
`;
const Card = tw.div`w-full mx-1 lg:mx-3 relative focus:outline-none`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}"); height: 650px;`,
  tw`bg-cover bg-no-repeat bg-center w-screen`,
]);

const Carousel = () => {
  const sliderSettings = {
    arrows: true,
    slidesToShow: 1,
    accessibility: true,
    autoplay: true,
    autoplaySpeed: 4000,
    draggable: true,
    fade: false,
    swipe: true,
    dots: true,
    infinite: true,
  };
  const content = [
    {
      id: 0,
      imageSrc: Image0,
      text:
        "Steel Containers (Storbox) is a modern industrial container used in the handling of heavy goods foundries, managing industrial scrap, and nuclear power industries to name a few",
      title: "Storbox",
    },
    {
      id: 1,
      imageSrc: Image1,
      text:
        "Generally Storbox can contain volume from approx 190 Litres to 2000 Litres",
      title: "Available in various sizes",
    },
    {
      id: 2,
      imageSrc: Image2,
      text: "Storbox comes in several models, colors and designs",
      title: "Wide range",
    },
    {
      id: 3,
      imageSrc: Image4,
      text:
        "We can also provide customized solutions as per the needs of our customers",
      title: "Customizable",
    },
    {
      id: 4,
      imageSrc: Image5,
      text:
        "Steel Containers (Storbox) is a modern industrial container used in the handling of heavy goods foundries, managing industrial scrap, and nuclear power industries to name a few",
      title: "Storbox",
    },
    {
      id: 5,
      imageSrc: Image6,
      text:
        "Generally Storbox can contain volume from approx 190 Litres to 2000 Litres",
      title: "Available in various sizes",
    },
    {
      id: 6,
      imageSrc: Image7,
      text: "Storbox comes in several models, colors and designs",
      title: "Wide range",
    },
  ];
  return (
    <Container>
      <Content>
        <CardSlider {...sliderSettings} tw="w-full py-4 flex items-center">
          {content.map((card, index) => (
            <div key={index} tw="w-full focus:outline-none">
              <Card key={index}>
                <CardImage
                  imageSrc={card.imageSrc}
                  tw="flex justify-between align-middle items-center"
                >
                  <span tw="text-center align-middle w-3/4 md:w-1/2 lg:w-1/4 bg-gray-700 mx-auto md:mx-24 mt-48 py-8 px-4 rounded-lg bg-opacity-75 shadow-xl">
                    <span tw="font-semibold text-3xl font-sans text-white">
                      {card.title}
                    </span>
                    <br />
                    <span tw="font-normal text-white">{card.text}</span>
                  </span>
                </CardImage>
              </Card>
            </div>
          ))}
        </CardSlider>
      </Content>
    </Container>
  );
};

export default Carousel;
