import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Image1 from "../../images/image007.jpg";
import Image2 from "../../images/image008.jpg";
import Image3 from "../../images/image012.png";
import Image4 from "../../images/image013.jpg";
import Image6 from "../../images/image030.png";
import Image7 from "../../images/image031.png";
import Image8 from "../../images/image032.png";
import Image9 from "../../images/image033.png";
import Image10 from "../../images/image034.png";
import Image11 from "../../images/image035.png";
import Container1 from "../../images/container1.png";
import Container2 from "../../images/container2.png";
import "../css/slider.css";

const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-81p sm:h-40width lgxl:h-72 w-full bg-contain bg-no-repeat bg-center`,
]);

const Grid = () => {
  const content = [
    {
      id: 1,
      imageSrc: Image10,
    },
    {
      id: 2,
      imageSrc: Image3,
    },
    {
      id: 3,
      imageSrc: Image11,
    },
    {
      id: 4,
      imageSrc: Image6,
    },
    {
      id: 5,
      imageSrc: Container1,
    },
    {
      id: 6,
      imageSrc: Container2,
    },
    {
      id: 7,
      imageSrc: Image7,
    },
    {
      id: 8,
      imageSrc: Image9,
    },
    {
      id: 9,
      imageSrc: Image1,
    },
    {
      id: 10,
      imageSrc: Image4,
    },
    {
      id: 11,
      imageSrc: Image8,
    },
    {
      id: 12,
      imageSrc: Image2,
    },
  ];
  return (
    <div tw="relative mx-auto py-8 px-8">
      <div tw="flex items-center flex-row flex-wrap justify-center">
        {content.map((image, index) => (
          <div tw="mt-4 w-full sm:w-1/2 lgxl:w-1/4" key={index}>
            <div tw="mx-2">
              <div tw="bg-cover bg-center rounded overflow-hidden shadow-2xl bg-white">
                <CardImage imageSrc={image.imageSrc} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Grid;
